
import styles from "./styles/ndaSection.module.css";


export const NDASection = () => {
    return (
        <div className={styles.ndaSection}>
            <div className={styles.title}>Non-Disclosure Agreement</div>
            <div className={styles.ndaText}>
                sI recognize and acknowledge that I have been given access to an restricted and protected area of confidential and privileged information.
                I agree not to share or disclose to anyone in any way whatsoever neither partially nor entirely the information, content and ideas that
                I was given access to on this website. I further acknowledge that the content may be subject to copyright protection.
                In case of breach of this agreement in particular by disclosing any of this information either partially or in its entirety I agree to pay a sum of USD 10'000.- for each single breach.
                In addition I agree to pay compensation for any worldwide damages that are caused directly or indirectly by non fulfilling this agreement. Applicable law is Swiss Law. I agree that this agreement is legally binding and enforcable by clicking the button "I agree". The Courts of Zurich, Switzlerand have sole jurisdiction.
            </div>

            <div className={styles.buttonContainer}>
                <div className={styles.yesButton}>YES, I agree</div><div className={styles.noButton}>No, I don't agree</div>
            </div>
        </div>
    )
}